import React,{ Component, useEffect } from 'react';
import { Header } from 'react-native-elements';
import db from '../firebase';
import { useDispatch, useSelector } from "react-redux";
import {
  selectPatientList,
  setPatientList
} from "../redux/reducers/patientSlice";
import { TouchableRipple } from 'react-native-paper';
import { View, Text, StyleSheet, ActivityIndicator, FlatList, Button } from 'react-native';
import PatientsList from "../components/PatientsList";

const HomeScreen = ({navigation}) => {
  
    return (
      <View style={styles.container}>
        {/* <StatusBar barStyle= { theme.dark ? "light-content" : "dark-content" }/> */}
        <Text>Home Screen</Text>
      {/* <Button
        title="Go to patients list screen"
        onPress={() => {}}
      /> */}
      </View>
    );
};



const styles = StyleSheet.create({
  container: {
    flex: 1, 
    alignItems: 'center', 
    justifyContent: 'center'
  },
});

export default HomeScreen;