import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import patientsReducer from "../redux/reducers/patientSlice";


export default configureStore({
  reducer: {
    patients: patientsReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});