import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  patientList: null
};

// more detials will be added in coming commits

const patientSlice = createSlice({
  name: "patients",
  initialState,
  reducers: {
    setPatientList: (state, action) => {
      state.patientList = action.payload.patientList;
    },

  },
});

export const { setPatientList } = patientSlice.actions;

export const selectPatientList = (state) => state.patients.patientList;


export default patientSlice.reducer;