import React, { useEffect } from 'react';
import { View, Text, StyleSheet, ActivityIndicator, FlatList } from 'react-native';
import { Image } from 'react-native';
import { TouchableRipple } from 'react-native-paper';
import { useDispatch, useSelector } from "react-redux";
import {
    selectPatientList,
    setPatientList
} from "../redux/reducers/patientSlice";

const department = (val) => {
    if(val == 1 ) {
        return "Talk Therapy"
    }
} 

const createdAt = (val) => {
    var date = new Date(val);
    return date.toLocaleDateString()
}

const PatientsList = (props) => {
    const patientList = useSelector(selectPatientList);
    console.log(patientList);

    const Item = ({ data }) => {
        return (<TouchableRipple style={styles.chatlist} rippleColor="white" onPress={() => {
            //this.props.ganda.navigate('Dr. Basith', { docID: data.id, name: data.userInfo.patientFName })
        }}>
            <View style={{ flexDirection: 'row', flex: 1 }}>
                <View style={{ flexDirection: 'column', flex: 0.25, alignItems: 'center' }}>
                    <Image style={{ height: 50, width: 50, borderRadius: 40 }} source={require('../assets/user.png')} ></Image>
                </View>

                <View style={{ flexDirection: 'column', flex: 0.50 }}>
                    <View style={{ flex: 1, flexDirection: 'row' }}>
                        <Text style={{ fontFamily: 'Nunito', fontSize: 14, color: '#222f2d' }}>{data.name}</Text>
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row' }}>
                        <Text style={{ fontFamily: 'Nunito-LightItalic', color: 'grey' }}>{department(1)}</Text>
                    </View>
                </View>

                <View style={{ flexDirection: 'column', flex: 0.25 }}>
                    <Text style={{ fontFamily: 'Nunito-Light', fontSize: 11, color: 'grey' }}>{createdAt(data.createdAt)}</Text>
                </View>
            </View>
        </TouchableRipple>)
    };

    const renderItem = ({ item }) => {
        return (<Item data={item} />)
    };

    return (
        <View>
            <FlatList
                data={patientList}
                renderItem={renderItem}
                keyExtractor={item => item.id}
                extraData={props}
                ItemSeparatorComponent={
                    (({ highlighted }) => (
                        <View
                            style={styles.break} />
                    ))
                }
                ListFooterComponent={
                    (({ highlighted }) => (
                        <View>
                            {patientList && <View
                                style={{ marginBottom: 33 }} />}
                        </View>
                    ))
                }
                ListHeaderComponent={
                    (({ highlighted }) => (
                        <View style={styles.header}>
                            {patientList && <Text style={styles.text}>Consultations</Text>}
                        </View>
                    ))
                }
            />
        </View>
    );
}


const styles = StyleSheet.create({
  break: {
    marginBottom: 5,
    marginTop: 5,
  },
  header: {
    marginLeft: 13,
    marginBottom: 13,
    fontFamily: 'Nunito',
    fontSize: 12
  },
  text: {
    fontSize: 14,
    fontFamily: 'Nunito-Regular',
    color: 'gray',
    letterSpacing: 0.7
  },
  chatlist: {
    marginLeft: 12,
    marginRight: 12,
    boxShadow: 'rgb(20 33 60 / 18%) 0px 5px 12px',
    paddingTop: 9,
    paddingBottom: 9,
    borderRadius: 5
  }
});

export default PatientsList;