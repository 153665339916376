import 'react-native-gesture-handler';
import React, { useState } from 'react';
import HomeScreen from './screens/HomeScreen';
import ConsultationScreen from './screens/ConsultationScreen';
import { AppRegistry } from 'react-native';
import { useFonts } from 'expo-font';
import store from "./redux/store";
import { Provider } from "react-redux";
import { NavigationContainer } from '@react-navigation/native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import RootNav from './navigation/RootNav';
import AsyncStorage from '@react-native-community/async-storage';
import DrawerContent from './components/DrawerContent';
import { HomeStackScreen, ConsultationStackScreen } from './navigation/AppNav';
import SignInScreen from './screens/SignInScreen';
import SignUpScreen from './screens/SignUpScreen';

const Drawer = createDrawerNavigator();


export default function App() {

  const [loggedIn, setloggedIn] = useState(true);

  let [fontsLoaded] = useFonts({
    'Nunito': require('./assets/fonts/Nunito-Bold.ttf'),
    'Nunito-Black': require('./assets/fonts/Nunito-Black.ttf'),
    'Nunito-Regular': require('./assets/fonts/Nunito-Regular.ttf'),
    'Nunito-Light': require('./assets/fonts/Nunito-Light.ttf'),
    'Nunito-SemiBold': require('./assets/fonts/Nunito-SemiBold.ttf'),
    'Nunito-ExtraBold': require('./assets/fonts/Nunito-ExtraBold.ttf'),
    'Nunito-LightItalic': require('./assets/fonts/Nunito-LightItalic.ttf')
  });

  return (
    
    <Provider store={store}>
      <NavigationContainer>
        {/* <Home /> */}

        {  loggedIn == false ? (

        <Drawer.Navigator initialRouteName="Home" drawerContent={props => <DrawerContent {...props} />}>
          {/* <Drawer.Screen name="HomeDrawer" component={MainTabScreen} /> */}
          <Drawer.Screen name="Home" component={SignInScreen} />
          <Drawer.Screen name="Consultations" component={SignUpScreen} />
          {/* <Drawer.Screen name="BookmarkScreen" component={BookmarkScreen} /> */}
        </Drawer.Navigator>
        )
        :
        <RootNav/>
        }
      </NavigationContainer>
    </Provider>)
}

// const AppSwitchNavigator = createSwitchNavigator({
//   // LoadingScreen: LoadingScreen,
//   // LoginScreen: LoginScreen,
//   HomeScreen: Home
// });

// const AppNavigator = createAppContainer(AppSwitchNavigator);

AppRegistry.registerComponent('medicAidsPanel',() => App);