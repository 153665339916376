import React,{ Component, useEffect } from 'react';
import { Header } from 'react-native-elements';
import db from '../firebase';
import { useDispatch, useSelector } from "react-redux";
import {
  selectPatientList,
  setPatientList
} from "../redux/reducers/patientSlice";
import { TouchableRipple } from 'react-native-paper';
import { View, Text, StyleSheet, ActivityIndicator, FlatList, Platform } from 'react-native';
import PatientsList from "../components/PatientsList";


const ConsultationScreen = (props) => {
  const dispatch = useDispatch();
  const pts = useSelector(selectPatientList); // call this in child component
  let patientList = [];

  let docName = "Anna Frued" // get this from logged in doctor

  useEffect(() => {
    // firebase query to get patients of a doctor
    var patients = db.collection("NUMBERS");
    patients.where("doctorName", "==", docName).get().then(querySnapshot => {
      querySnapshot.docs.map(doc => {
        const pList = doc.data();
        patientList = [...patientList, { id: doc.id, ...pList }];
      });
      // set or dispatch state
      dispatch(
        setPatientList({
          patientList: patientList
        })
      );
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  }, [docName]);


  return (
    <View>
      {/* <View style={styles.header}></View> */}
      <PatientsList></PatientsList>      
    </View>    
  );

}


const styles = StyleSheet.create({


header: {
    position: 'absolute',
    backgroundColor: '#1c1c1c',
    left: 0,
    right: 0,
    width: '100%',
    zIndex: 1,
  },
  headerContainer: {
    ...Platform.select({
        web: {
          position: "fixed",
          backgroundColor: '#009387'
        }
      }),
      height: 60,
      width: '100%',
  }
})


export default ConsultationScreen;