import React,{ Component, useEffect } from 'react';
import { Header } from 'react-native-elements';
import { View, Text, StyleSheet, ActivityIndicator, FlatList, Platform } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';

const CustomHeader = (props) => {
    return(

        <View style={styles.headerContainer}>
            <Icon.Button name="ios-menu" size={25} backgroundColor="#009387" onPress={() => props.navigation.openDrawer()}></Icon.Button>
            <Text>{props.title}</Text>
        </View>
        
    )
}




const styles = StyleSheet.create({


    header: {
        position: 'absolute',
        backgroundColor: '#1c1c1c',
        left: 0,
        right: 0,
        width: '100%',
        zIndex: 1,
    },
    headerContainer: {
        ...Platform.select({
            web: {
                position: "fixed",
                backgroundColor: '#009387'
            }
        }),
        height: 60,
        width: '100%',
    }
});

export default CustomHeader;