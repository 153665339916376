import firebase from 'firebase';


// PROVIDE VALID FIREBASE CONFIG HERE
// https://firebase.google.com/docs/web/setup

const FIREBASE_CONFIG = {
    apiKey: "AIzaSyBU4StvIWMDTwuTR_PqQ73oyGQYgh5cP-k",
      authDomain: "medikaids-649e1.firebaseapp.com",
      projectId: "medikaids-649e1",
      storageBucket: "medikaids-649e1.appspot.com",
      messagingSenderId: "1034398649876",
      appId: "1:1034398649876:web:f67166b6f18ce794ca2101",
      measurementId: "G-7M12K200JR"
  };
  

const firebaseApp = firebase.initializeApp(FIREBASE_CONFIG);
const db = firebaseApp.firestore();
const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();
const storage = firebase.storage();


export { auth, provider, storage };
export default db;